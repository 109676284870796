import React from "react";
import { useRef } from "react";
import { motion } from "framer-motion";
import logo from "../Images/Logo.png"
import { Footer } from "../Components/Footer";
import whitebg from "../Images/background/whitetexture.jpg";
import Gravitas from "../Images/Gravitas_tec.jpg";
import Mision_1 from "../Images/Mision_1.jpg";
import Mision_2 from "../Images/Mision_2.jpg";
import Background from "../Images/background/normalbg.jpg";
import { useInView } from "framer-motion";




export default function SobreNosotros() {
    const ref = useRef(null)
    const isInView = useInView(ref)
    const whiteimg = whitebg;
    const Logo = logo;
    //const Softwareimg = Software;


    return (
        <div className="h-auto w-full flex flex-col items-center justify-center pt-20 font-quicksand overflow-hidden">
            <div className="h-screen w-full max-w-7xl flex items-center justify-center px-10">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                    <div className="flex flex-col items-start justify-center order-2 lg:order-1">
                        <h1 className="md:text-5xl text-4xl font-quicksand font-bold text-left animate__animated animate__bounceInDown">
                            Bienvenido a Gravitas
                        </h1>
                        <p className="font-quicksand mt-10 md:text-2xl sm:text-md text-left animate__animated animate__bounceInLeft">


                            Gravitas es una empresa dedicada a la reestructuración y renovación de procesos. Desde nuestra fundación, nos hemos comprometido a proporcionar soluciones innovadoras para nuestros clientes y contribuir al desarrollo sostenible de la misma.
                        </p>
                    </div>
                    <div className=" flex items-center justify-center h-auto order-1 lg:order-2 animate__animated animate__backInRight">
                        <img className=" h-full  rounded-md drop-shadow-xl" src={Gravitas} alt="" />
                    </div>

                </div>
            </div>
            <div className="h-auto px-10 w-full max-w-7xl py-20">
                <motion.div className="py-10 " initial={{y:-20}} whileInView={{y:0}} transition={{ delay: 1 }} viewport={{ once: false}}>
                    <p className="text-4xl md:text-5xl font-bold">Lo que somos</p>
                </motion.div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 pt-12">
                    <motion.div initial={{}}>
                        <img className="rounded-md" src={Mision_2} alt="" />
                    </motion.div>
                    <div className="">
                        <h1 className="text-4xl font-quicksand font-bold">Nuestra Visión</h1>
                        <p className="font-quicksand mt-10 md:text-2xl sm:text-md">Ser líderes en la transformación empresarial, ofreciendo soluciones innovadoras y sostenibles que impulsen el crecimiento y la excelencia en los procesos de nuestros clientes.</p>
                    </div>

                    <div>
                        <img className="rounded-md" src={Mision_1} alt="" />
                    </div>
                    <div className="">
                        <h1 className="text-4xl font-quicksand font-bold">Nuestra Misión</h1>
                        <p className="font-quicksand mt-10 md:text-2xl sm:text-md"> Facilitar la reestructuración y renovación de procesos empresariales, brindando soluciones a medida que optimicen la eficiencia, la productividad y la sostenibilidad, promoviendo así el éxito a largo plazo de nuestros clientes.</p>
                    </div>
                    <div className="col-span-1 lg:col-span-2 gap-10">
                        <h1 className="text-4xl font-quicksand font-bold">Nuestros Valores</h1>
                        <div className="grid grid-cols-1 lg:grid-cols-4 pt-12 gap-5">
                            <div className="relative bg-no-repeat bg-cover rounded-md h-72 " style={{ backgroundImage: `url(${Background})` }}>
                                <div className=" p-10 h-full text-white text-center rounded-md">
                                    <h1 className=" font-bold text-2xl">Excelencia</h1>
                                    <p className="font-semibold pt-4"> Nos esforzamos por alcanzar la excelencia en todo lo que emprendemos, desde la calidad de nuestros productos hasta la atención al cliente.</p>
                                </div>
                            </div>
                            <div className="relative bg-no-repeat bg-cover rounded-md h-72 " style={{ backgroundImage: `url(${Background})` }}>
                                <div className="p-10 h-full text-white text-center rounded-md">
                                    <h1 className=" font-bold text-2xl">Integridad</h1>
                                    <p className="font-semibold pt-4"> Actuamos con honestidad, transparencia y responsabilidad en todas nuestras relaciones y operaciones.</p>
                                </div>
                            </div>
                            <div className="relative bg-no-repeat bg-cover rounded-md h-72 " style={{ backgroundImage: `url(${Background})` }}>
                                <div className=" p-10 h-full text-white text-center rounded-md">
                                    <h1 className=" font-bold text-2xl">Innovación</h1>
                                    <p className="font-semibold pt-4"> Fomentamos la creatividad y la innovación en la búsqueda constante de soluciones mejores y más eficientes.</p>
                                </div>
                            </div>
                            <div className="relative bg-no-repeat bg-cover rounded-md h-72 " style={{ backgroundImage: `url(${Background})` }}>
                                <div className=" p-10 h-full text-white text-center rounded-md">
                                    <h1 className=" font-bold text-2xl">Compromiso</h1>
                                    <p className="font-semibold pt-4">Estamos comprometidos con el éxito de nuestros clientes, empleados y comunidades en las que operamos.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="w-full">
                <Footer />
            </div>




        </div>

    )
}