import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import menu from "../Icons/menu.png";
import close from "../Icons/close.png";
import Logo2 from "../Images/Logo3.png";


export default function NavBar() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const location = useLocation();
   
    const logo = Logo2;

let Links = [
    { name: "Sobre nosotros", link: "/sobrenosotros" },
    { name: "Nuestras Soluciones", link: "/soluciones" },
    { name: "Contactanos", link: "/contact"},
   
  ];
    let [open, setOpen] = useState(false);

    const toggleMenu = () => {
        setOpen(!open);
    };

    useEffect(() => {
            const handleScroll = () => {
                const position = window.scrollY;
                setScrollPosition(position);
            };

            window.addEventListener('scroll', handleScroll, { passive: true });

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    );

    const Navbarclassname = scrollPosition <= 50 ?
        `top-0 w-full fixed z-40 px-10 ${open ? "bg-white": ""}`
        : "transition-bg bg-white top-0 w-full fixed z-40 px-10"

    

    return (
        <header className={Navbarclassname}>
            <div className="md:flex items-center justify-between py-2 ">
                <div className="flex items-center pt-4">
                    <a href="/gravitas" className="text-3xl font-pirulen ">
                        <img className="h-16 w-56 object-cover" src={logo} alt="" />
                    </a>
                </div>
                <div className="text-2xl absolute right-8 top-9 cursor-pointer lg:hidden" onClick={toggleMenu}>
                    <span>
                        <img className="h-5 w-5" src={open ? close : menu} alt="Menu Icon"/>
                    </span>
                </div>
                <ul
                    className={`lg:z-auto z-[-1] md:top-20 lg:items-center justify-center items-center right-0 w-full lg:w-auto lg:flex lg:pb-0 lg:pl-0  xl:h-auto pb-10 lg:static absolute ${open ? "bg-white xl:bg-transparent text-black" : "hidden"}`}
                >
                    {Links.map((link) => (
                       
                            <Link 
                                className="xl:hover:bg-transparent hover:bg-gray-100 md:hover:bg-none flex xl:py-0 py-4 px-6 mx-10 items-center rounded-md border-gray-300 cursor-pointer"
                                to={link.link} onClick={toggleMenu} key={link.name}
                            >
                                <li className="text-lg font-semibold  hover:text-blue-900">{link.name}</li>
                            </Link>
                    
                    ))}
                </ul>
            </div>
        </header>
    );
}
